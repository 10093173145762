import React from "react";
import { Provider } from "react-redux";
import store from "../lib/state/store";
import DocPreview from "../components/DocumentViewer/DocPreview";
import PreviewDocumentWrapper from "../components/Modals/PreviewDocumentWrapper.js";
import componentMount from "../lib/componentMount";

self.prc = self.prc || {};
self.prc.DocPreview = componentMount(props => <Provider store={store}><DocPreview {...props} /></Provider>);
self.prc.PreviewDocumentWrapper = componentMount(props => <Provider store={store}><PreviewDocumentWrapper {...props} /></Provider>);

